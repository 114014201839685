import React from 'react';
import { DateTime } from 'luxon';
import { clsx } from 'clsx';


const Concerts = () => {
  const arData = [

    {
      date: DateTime.fromFormat('17/10/2021', 'dd/MM/yyyy'),
      titre: 'Paulée de la Côte chalonnaise',
      ville: 'Chalon-sur-Saône',
      pays: 'FR',
    },

    {
      date: DateTime.fromFormat('10/02/2023', 'dd/MM/yyyy'),
      titre: 'Maison des Artistes',
      ville: 'Chamonix',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('17/12/2022', 'dd/MM/yyyy'),
      titre: 'Les Clameurs',
      ville: 'Lyon',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('18/11/2022', 'dd/MM/yyyy'),
      titre: 'L\'Alternative',
      ville: 'Les Olmes',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('17/11/2022', 'dd/MM/yyyy'),
      titre: 'Brasserie de l\'Amour',
      ville: 'Villeurbanne',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('11/09/2022', 'dd/MM/yyyy'),
      titre: 'Festival Les Eclats',
      ville: 'Dieulefit',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('10/09/2022', 'dd/MM/yyyy'),
      titre: 'L\'Enrobée',
      ville: 'Villeurbanne',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('27/08/2022', 'dd/MM/yyyy'),
      titre: 'Festival Ondes-et-Bal',
      ville: 'St-Germain-Nuelles',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('16/03/2023', 'dd/MM/yyyy'),
      titre: 'Bar l\'amicale',
      ville: 'Lyon',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('10/06/2023', 'dd/MM/yyyy'),
      titre: 'Festival Les Olmes',
      ville: 'Les Olmes',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('27/06/2023', 'dd/MM/yyyy'),
      titre: 'Festival Sapeytille',
      ville: 'Le Sappey en Chartreuse',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('11/08/2023', 'dd/MM/yyyy'),
      titre: 'Guinguette du Bout du Lac',
      ville: 'Bleymard',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('13/08/2023', 'dd/MM/yyyy'),
      titre: 'Créperie de la Rozell',
      ville: 'Crest',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('14/08/2023', 'dd/MM/yyyy'),
      titre: 'Marché du tiroir',
      ville: 'Suze sur Crest',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('15/08/2023', 'dd/MM/yyyy'),
      titre: 'Concert Ivrensemble',
      ville: 'Buis-les-Baronnies',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('10/09/2023', 'dd/MM/yyyy'),
      titre: 'Festival Eclats',
      ville: 'Dieulefit',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('23/09/2023', 'dd/MM/yyyy'),
      titre: 'La bobine',
      ville: 'Grenoble',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('10/11/2023', 'dd/MM/yyyy'),
      titre: 'Bistro Le Solaure',
      ville: 'Piègros-la-Clastre',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('11/11/2023', 'dd/MM/yyyy'),
      titre: 'Le Tiers Lieu du Parc',
      ville: 'Dieulefit',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('23/02/2024', 'dd/MM/yyyy'),
      titre: 'Café du court',
      ville: 'Reillane',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('24/02/2024', 'dd/MM/yyyy'),
      titre: 'Concert',
      ville: 'Roumoules',
      pays: 'FR',
    },
    {
      date: DateTime.fromFormat('10/04/2024', 'dd/MM/yyyy'),
      titre: 'Déambulation de rue',
      ville: 'Lyon',
      pays: 'FR',
    },
  ];

  return (
    <div className="dateTables-parent">
      <table className="dateTables">
        {arData.sort((d1, d2) => -d1.date.toMillis() + d2.date.toMillis()).map(({
          date, titre, ville, pays,
        }, index) => (
          <tr key={index} className={clsx(date < DateTime.local() && 'dateTables-passed')}>
            <td>{date.toFormat('dd/MM/yyyy')}</td>
            <td>{titre}</td>
            <td>{ville}</td>
            <td>{pays}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default Concerts;
